exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[id]-tsx": () => import("./../../../src/pages/[id].tsx" /* webpackChunkName: "component---src-pages-[id]-tsx" */),
  "component---src-pages-gerador-de-cnpj-tsx": () => import("./../../../src/pages/gerador-de-cnpj.tsx" /* webpackChunkName: "component---src-pages-gerador-de-cnpj-tsx" */),
  "component---src-pages-gerador-de-cpf-tsx": () => import("./../../../src/pages/gerador-de-cpf.tsx" /* webpackChunkName: "component---src-pages-gerador-de-cpf-tsx" */),
  "component---src-pages-gerador-de-numeros-para-aleatorios-tsx": () => import("./../../../src/pages/gerador-de-numeros-para-aleatorios.tsx" /* webpackChunkName: "component---src-pages-gerador-de-numeros-para-aleatorios-tsx" */),
  "component---src-pages-gerador-de-numeros-para-loterias-tsx": () => import("./../../../src/pages/gerador-de-numeros-para-loterias.tsx" /* webpackChunkName: "component---src-pages-gerador-de-numeros-para-loterias-tsx" */),
  "component---src-pages-gerador-de-numeros-para-sorteios-tsx": () => import("./../../../src/pages/gerador-de-numeros-para-sorteios.tsx" /* webpackChunkName: "component---src-pages-gerador-de-numeros-para-sorteios-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

